
import { api } from "@/api/api";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseConfirmModalForm from "@/components/shared/modal/BaseConfirmModalForm.vue";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "SendToApprovalModal",
  components: {
    BaseModal,
    BaseConfirmModalForm,
  },
  props: {
    modalData: {
      type: Object as PropType<ModalBaseData>,
      required: true,
    },
    courseId: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore<StoreState>();

    const sendInvoiceBasisToApproval = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await api.clientaccount.sendClientAccountInvoiceBasisForApproval(
          props.courseId,
          props.modalData.existingItem.invoiceBasisNumber
        );
        openNotification(store, NotificationItemType.Success, "Fakturagrunnlag ble sendt til godkjenning.");
        emit("update");
      });
    };

    return {
      sendInvoiceBasisToApproval,
    };
  },
});
