var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "loading": _vm.loading,
      "editable": ""
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "submit": _vm.onSubmit
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "required",
    attrs: {
      "label": "Antall deltakere på kurset",
      "hide-details": "auto",
      "type": "number"
    },
    model: {
      value: _vm.formValues.numberOfParticipants,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "numberOfParticipants", $$v);
      },
      expression: "formValues.numberOfParticipants"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.invoiceBasisType === _vm.InvoiceBasisType.InvoiceBasis ? _c('v-text-field', {
    staticClass: "required",
    attrs: {
      "label": "Forbundets andel av registrerte kostnader",
      "hide-details": "auto",
      "type": "number",
      "prefix": "kr"
    },
    model: {
      value: _vm.formValues.shareOfRegisteredCosts,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "shareOfRegisteredCosts", $$v);
      },
      expression: "formValues.shareOfRegisteredCosts"
    }
  }) : _c('v-text-field', {
    staticClass: "required",
    attrs: {
      "label": "Endring",
      "hide-details": "auto",
      "rules": _vm.validateNotEmpty,
      "type": "number",
      "prefix": "kr"
    },
    model: {
      value: _vm.formValues.shareOfRegisteredCosts,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "shareOfRegisteredCosts", $$v);
      },
      expression: "formValues.shareOfRegisteredCosts"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "required",
    attrs: {
      "label": "Forbundets andel av kursavgift",
      "hide-details": "auto",
      "type": "number",
      "prefix": "kr"
    },
    model: {
      value: _vm.formValues.shareOfCourseFee,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "shareOfCourseFee", $$v);
      },
      expression: "formValues.shareOfCourseFee"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }