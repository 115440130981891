var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.modalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 400
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('BaseConfirmModalForm', {
    attrs: {
      "modalBaseData": _vm.modalData
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "submit": _vm.sendInvoiceBasisToApproval
    }
  }, [_vm._v(" Ønsker du å sende dette fakturagrunnlaget til godkjenning? ")])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }