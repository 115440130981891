export enum InvoiceBasisStatus {
  Draft = "utkast",
  ToApproval = "til godkjenning",
  Approved = "godkjent",
  Set = "innstilt",
  Transfered = "overfort",
}

export enum InvoiceBasisType {
  InvoiceBasis = "Fakturagrunnlag",
  Change = "Endringsgrunnlag",
}
