
import { api } from "@/api/api";
import { ApiClientAccountInvoicePartBasisDto, ApiCreateClientAccountInvoicePartBasisDto } from "@/api/generated/Api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { InvoiceBasisType } from "@/shared/enums/ClientAccount.enum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { validateNonNegativeNumber, validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { PropType, defineComponent, ref } from "@vue/composition-api";
import Vue from "vue";

export default defineComponent({
  name: "PartInvoiceBasisForm",
  components: { BaseModalForm },
  props: {
    modalData: {
      type: Object as PropType<ModalBaseData>,
      required: true,
    },
    invoicePartBasis: {
      type: Object as PropType<ApiClientAccountInvoicePartBasisDto>,
      required: true,
    },
    invoicePartBases: {
      type: Array as PropType<ApiCreateClientAccountInvoicePartBasisDto[]>,
      required: true,
    },
    isCreating: {
      type: Boolean,
      default: false,
    },
    invoiceBasisType: {
      type: String as PropType<InvoiceBasisType>,
      required: true,
    },
    templateQueries: {
      type: Object,
      required: false,
    },
  },
  emits: ["close", "update", "fetchInvoiceBasisDetails"],
  setup(props, { refs, emit }) {
    const route = useRoute();
    const formValues = useAutoDeepCloneProp<ApiCreateClientAccountInvoicePartBasisDto>(props, "invoicePartBasis");
    const store = useStore<StoreState>();
    const loading = ref(false);

    const onSubmit = () => {
      const isValid = (refs.form as Vue & { validate: () => boolean }).validate();

      if (!isValid) {
        return;
      }

      if (props.isCreating) {
        emit("update", formValues.value);
        return;
      }

      const data = props.invoicePartBases.map((x) => {
        if (x.memberOrganizationId === props.invoicePartBasis.memberOrganizationId) {
          x = {
            memberOrganizationId: +props.invoicePartBasis.memberOrganizationId,
            numberOfParticipants: formValues.value?.numberOfParticipants || null,
            shareOfCourseFee: formValues.value?.shareOfCourseFee || null,
            shareOfRegisteredCosts: formValues.value?.shareOfRegisteredCosts || null,
          };
        }

        return {
          ...x,
        };
      });

      try {
        api.clientaccount.updateClientAccountInvoiceBasis(+route.params.id, +route.params.invoiceBasisId, {
          createClientAccountInvoicePartBasis: data,
        });
      } catch (error: any) {
        error.response.data.validationList[0].validationErrors?.forEach((e: any) => {
          openNotification(store, NotificationItemType.Error, `Valideringsfeil: ${e}`);
        });
      }

      loading.value = true;

      setTimeout(() => {
        emit("fetchInvoiceBasisDetails");
        emit("close");
        loading.value = false;
        openNotification(store, NotificationItemType.Information, "Oppdatering vellykket");
      }, 2000);
    };

    return {
      onSubmit,
      formValues,
      validateNonNegativeNumber,
      validateNotEmpty,
      InvoiceBasisType,
      loading,
    };
  },
});
