var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [!_vm.isTemplate ? [_c('BaseSheetHeader', {
    staticClass: "pl-0",
    attrs: {
      "data-cy": "invoice-basis-details-status",
      "title": "Status"
    }
  }), _c('v-row', _vm._l(_vm.invoiceDetailsStatusRows, function (item, i) {
    return _c('v-col', {
      key: "".concat(i, "-status-field"),
      staticClass: "pa-2",
      attrs: {
        "cols": "6"
      }
    }, [_c('BaseIconCard', _vm._b({
      attrs: {
        "link": false,
        "captionAbove": "",
        "dense": ""
      }
    }, 'BaseIconCard', item, false))], 1);
  }), 1)] : _vm._e()], 2), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('BaseSheetHeader', {
    staticClass: "pl-0",
    attrs: {
      "title": "Del 1"
    }
  }), _c('v-row', _vm._l(_vm.invoiceDetailsPartOne, function (item, i) {
    return _c('v-col', {
      key: "".concat(i, "-partOne-field"),
      staticClass: "pa-2",
      attrs: {
        "cols": "6"
      }
    }, [_c('BaseIconCard', _vm._b({
      attrs: {
        "link": false,
        "captionAbove": "",
        "dense": "",
        "alignment": "start",
        "fillHeight": ""
      }
    }, 'BaseIconCard', item, false))], 1);
  }), 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('BaseSheetHeader', {
    staticClass: "pl-0",
    attrs: {
      "title": "Del 2"
    }
  }), _c('v-row', _vm._l(_vm.invoiceDetailsPartTwo, function (item, i) {
    return _c('v-col', {
      key: "".concat(i, "-partTwo-field"),
      staticClass: "pa-2",
      attrs: {
        "cols": "6"
      }
    }, [_c('BaseIconCard', _vm._b({
      attrs: {
        "link": false,
        "captionAbove": "",
        "dense": "",
        "alignment": "start",
        "fillHeight": ""
      }
    }, 'BaseIconCard', item, false))], 1);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }