var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.modalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 400
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('BaseConfirmModalForm', {
    attrs: {
      "headline": _vm.isDraft ? 'Slett fakturagrunnlag' : 'Avslå fakturagrunnlag',
      "modalBaseData": _vm.modalData
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "submit": _vm.rejectInvoiceBasis
    }
  }, [_vm._v(" " + _vm._s(_vm.isDraft ? "Er du sikker på at du vil slette dette fakturagrunnlagsutkastet?" : "Ønsker du å avslå dette fakturagrunnalaget?") + " ")])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }