
import { api } from "@/api/api";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseConfirmModalForm from "@/components/shared/modal/BaseConfirmModalForm.vue";
import { InvoiceBasisStatus } from "@/shared/enums/ClientAccount.enum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "RejectAndDeleteModal",
  components: {
    BaseModal,
    BaseConfirmModalForm,
  },
  props: {
    modalData: {
      type: Object as PropType<ModalBaseData>,
      required: true,
    },
    courseId: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore<StoreState>();
    const isDraft = computed<boolean>(() =>
      props.modalData.existingItem ? props.modalData.existingItem.status === InvoiceBasisStatus.Draft : true
    );

    const rejectInvoiceBasis = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const request = () =>
          isDraft.value
            ? api.clientaccount.deleteClientAccountInvoiceBasis(
                props.courseId,
                props.modalData.existingItem.invoiceBasisNumber
              )
            : api.clientaccount.rejectClientAccountInvoiceBasis(
                props.courseId,
                props.modalData.existingItem.invoiceBasisNumber
              );
        await request();

        openNotification(
          store,
          NotificationItemType.Information,
          isDraft.value ? "Fakturagrunnlag er slettet." : "Fakturagrunnlaget er avvist."
        );
        emit("update");
      });
    };

    return {
      isDraft,
      rejectInvoiceBasis,
      InvoiceBasisStatus,
    };
  },
});
